<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      spinner-variant="primary"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search programs"
            type="text"
          />
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >

        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
          >
            <b-avatar
              :src="props.row.avatar"
              class="mx-1"
            />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Buttons -->
          <span v-else-if="props.column.field === 'buttons'">
            <b-button
              variant="outline-primary"
              size="sm"
              :to="{name: 'program-manager', params: {id: props.row.id}}"
            >Program Manager</b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>
<script>

import {
  BBadge, BButton, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import store from '@/store'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    // ActionFormBuilder,
    // SendReminderFormBuilder,
    // AssignPartnerFormBuildery,
    // AssignTeamFormBuilder,
    // vSelect,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Program Status',
          field: 'status',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Startup Status',
          field(row) {
            return row.programs_applicantstables[0].programs_startupparticipants.length ? 'Participant' : 'Applicant'
          },
          tdClass: 'text-capitalize',
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },

      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        scheduled: 'light-primary',
        discontinued: 'light-danger',
        draft: 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      return arr.map(e => e.organization_id)
        .filter(obj => obj) // Remove nulls
    },
  },
  apollo: {
    rows: {
      query() {
        // Association through users_organizationtable: Checks association of incubator
        // Association through programs_applicantstables: Checks association of startup
        return gql`
        query startup_programs {
          programs_basicinfo(where: {is_program: {_eq: true},
          users_organizationtable: {usersAssociationtablesByAssociatedOrg: {id: {_eq: ${this.$route.params.id} }}},
          programs_applicantstables: {users_organizationtable: {users_associationtables: {id: {_eq: ${this.$route.params.id} }}}}}) {
           id
           title
           status
           programs_applicantstables(where: {users_organizationtable: {users_associationtables: {id: {_eq: ${this.$route.params.id} }}}}){
            programs_startupparticipants {
              id
            }
           }
          }
      }`
      },
      update: data => data.programs_basicinfo,
    },
  },
}
</script>
